import React from 'react';
import Layout from "../components/layout"

export default function NotFoundPage({ location }) {

  return (
    <Layout>
      <h1>Oops.</h1>
      <p>You’ve stumbled on a missing page. But don’t worry, there’s still loads to explore. Head on over to our home page to try again.</p>
      <a href="/">Go Home</a>
    </Layout>
  )
}